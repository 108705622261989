class Store {

    #client = null;

    #container = null;

    #collection = null;

    #product = null;

    #variant = null;

    constructor() {
        // this.#client = window.ShopifyBuy.buildClient({
        //     domain: window.Shopify.domain,
        //     storefrontAccessToken: window.Shopify.storefrontAccessToken
        // });

        // document.addEventListener("DOMContentLoaded", () => {
        //     this.#container = document.querySelector('.shop-container');
        //     if (this.#container) {
        //         this.initialize();
        //     }
        // });
    }

    initialize() {
        this.getCollection(window.Shopify.collectionId, (collection) => {
            if ( this.#container.classList.contains('container-collection') ) {
                this.#collection = collection;
                this.renderCollection(collection.products);
            } else if ( this.#container.classList.contains('container-product') ) {
                const id = window.location.pathname.replace('/shop/product/', '') || null;
                const product = id ? collection.products.filter((product) => product.id.replace('gid://shopify/Product/', '') == id) : null;
                if (product) {
                    this.getProductMetafields(product.id, (metafields) => {
                        this.#product = product[0];
                        console.log(this.#product);
                        console.log(metafields);
                        this.renderProduct(product[0], metafields);
                    });
                }
            }
        }, 25);
    }

    renderCollection(products) {
        this.#container.innerHTML = '';
        const row = document.createElement('div');
        const collection = document.createElement('div');
        const template = _.template( document.getElementById('template-product').innerHTML );
        collection.className = 'collection';
        row.className = 'row g-3';
        products.forEach((product) => {
            const item = template({
                product: product
            });
            row.innerHTML += item;
        });
        collection.appendChild(row);
        this.#container.appendChild(collection);
    }

    renderProduct(product, metafields) {
        this.#container.innerHTML = '';
        const template = _.template( document.getElementById('template-product').innerHTML );
        this.#container.innerHTML = template({
            product: product
        });
        //
        const options = this.#container.querySelectorAll('.list-options');
        options.forEach((option) => {
            const inputs = option.querySelectorAll('input[type=radio]');
            option.addEventListener('change', () => {
                inputs.forEach((input) => {
                    if (input.checked) {
                        const header = option.parentNode.querySelector('.option-header span');
                        header.textContent = ` | ${input.value}`;
                    }
                });
            });
            const first = inputs[0];
            first.checked = true;
            first.dispatchEvent(new Event('change', { bubbles: true, cancelable: false }));
        });
    }

    getCollection(id, callback, limit = 10) {
        const hash = md5(id);
        const cached = localStorage.getItem(hash) || null;
        if (! cached ) {
            this.#client.collection.fetchWithProducts(id, { productsFirst: limit }).then((collection) => {
                localStorage.setItem(hash, JSON.stringify(collection));
                callback(collection);
            });
        } else {
            callback( JSON.parse(cached) );
        }
    }

    getProductMetafields(id, callback) {
        const hash = md5(id);
        const cached = localStorage.getItem(hash) || null;
        if (! cached ) {

            // Build a custom products query using the unoptimized version of the SDK
            const productsQuery = this.#client.graphQLClient.query((root) => {
                root.addConnection('products', { args: { first: 10 } }, (product) => {
                    product.add('title');
                    product.add('tags');
                    product.add('metafields', {
                        args: {
                            identifiers: [
                                {
                                    key: "color",
                                    namespace: "main"
                                },
                                {
                                    key: "gender",
                                    namespace: "main"
                                }
                            ]
                        }
                    }, (metafield) => {
                        metafield.add('value');
                    });
                });
            });

            // Call the send method with the custom products query
            this.#client.graphQLClient.send(productsQuery).then(({model, data}) => {
              // Do something with the products
              console.log(model);
            });


            // // Build a custom products query using the unoptimized version of the SDK
            // const productQuery = this.#client.graphQLClient.query((root) => {
            //     root.addConnection('product', { args: { id: id } }, (product) => {
            //         product.addConnection('metafields', { args: { first: 10 } }, (metafield) => {
            //                 metafield.add('key')
            //                 metafield.add('value')
            //             }
            //         )
            //     });
            // });

            // // Call the send method with the custom products query
            // this.#client.graphQLClient.send(productQuery).then(({model, data}) => {
            //     // Do something with the products
            //     console.log(model);
            //     console.log(data);
            //     // localStorage.setItem(hash, JSON.stringify(collection));
            //     callback(collection, []);
            // });
        } else {
            callback( JSON.parse(cached) );
        }
    }
}

export { Store };
