import { Store } from './Store.js';

class App {

    #store = null;

    constructor() {
        this.#store = new Store();

        document.addEventListener('alpine:init', () => {
            Alpine.data('utils', () => ({
                goBack() {
                    history.back();
                },
                copy() {
                    const content = this.$el.dataset.content || null;
                    const icon = this.$el.querySelector('i');
                    const className = icon.className;
                    if (content) {
                        try {
                            navigator.clipboard.writeText(content);
                            icon.className = 'fa-regular fa-fw fa-circle-check';
                            setTimeout(() => {
                                icon.className = className;
                            }, 500)
                        } catch (err) {
                            //
                        }
                    }
                },
                unmask() {
                    const content = this.$el.dataset.content || null;
                    const icon = this.$el.querySelector('i');
                    const className = icon.className;
                    const target = document.querySelector(this.$el.dataset.target || null);
                    if (content && target.length) {
                        icon.className = 'fa-solid fa-fw fa-unlock opacity-25';
                        target.text(content);
                    }
                },
                reveal() {
                    const className = this.$el.className;
                    const target = document.querySelector(this.$el.dataset.target || null);
                    if (target) {
                        switch( target.type ) {
                            case 'password':
                                this.$el.className = 'fa-solid fa-unlock text-primary';
                                target.type = 'text';
                            break;
                            case 'text':
                                this.$el.className = 'fa-solid fa-lock opacity-25';
                                target.type = 'password';
                            break;
                        }
                    }
                },
                submit() {
                    const form = this.$el.closest('form');
                    form.submit();
                    this.$el.disabled = true;
                },
                triggerClick() {
                    const target = document.querySelector(this.$el.dataset.target || null);
                    target.trigger('click');
                },
                showMobileMenu() {
                    const menu = document.querySelector('.mobile-menu');
                    const overlay = menu.querySelector('.overlay');
                    const nav = menu.querySelector('nav');
                    const button = menu.querySelector('.btn-menu-close');
                    const items = menu.querySelectorAll('li');
                    menu.classList.add('is-open');
                    anime({
                        targets: overlay,
                        easing: 'easeOutQuad',
                        duration: 350,
                        opacity: [0, 1],
                    });
                    anime({
                        targets: nav,
                        easing: 'easeOutQuad',
                        duration: 350,
                        delay: 100,
                        opacity: [0, 1],
                        translateX: [15, 0]
                    });
                    anime({
                        targets: button,
                        easing: 'easeOutQuad',
                        duration: 450,
                        delay: 200,
                        opacity: [0, 1]
                    });
                    anime({
                        targets: items,
                        easing: 'easeOutQuad',
                        duration: 350,
                        delay: anime.stagger(100, { start: 300 }),
                        opacity: [0, 1],
                        translateX: [-15, 0]
                    });
                },
                hideMobileMenu() {
                    const menu = document.querySelector('.mobile-menu');
                    const overlay = menu.querySelector('.overlay');
                    const nav = menu.querySelector('nav');
                    const button = menu.querySelector('.btn-menu-close');
                    const items = menu.querySelectorAll('li');
                    anime({
                        targets: button,
                        easing: 'easeOutQuad',
                        duration: 250,
                        opacity: [1, 0]
                    });
                    anime({
                        targets: items,
                        easing: 'easeOutQuad',
                        duration: 250,
                        delay: anime.stagger(50),
                        opacity: [1, 0],
                        translateX: [0, -15]
                    });
                    anime({
                        targets: nav,
                        easing: 'easeOutQuad',
                        duration: 350,
                        delay: 300,
                        opacity: [1, 0],
                        translateX: [0, 15]
                    });
                    anime({
                        targets: overlay,
                        easing: 'easeOutQuad',
                        duration: 250,
                        delay: 500,
                        opacity: [1, 0],
                        complete: () => {
                            menu.classList.remove('is-open');
                        }
                    });
                },
            }));

            Alpine.data('faqs', () => ({
                container: null,
                init() {
                    this.container = this.$el;
                },
                toggle() {
                    const faq = this.$el.parentNode;
                    const other = this.container.querySelectorAll('.faq.is-open');
                    if ( faq.classList.contains('is-open') ) {
                        faq.classList.remove('is-open');
                    } else {
                        other.forEach((faq) => {
                            faq.classList.remove('is-open');
                        });
                        faq.classList.add('is-open');
                        const answer = faq.querySelector('.faq-answer');
                        anime({
                            targets: answer,
                            easing: 'easeOutQuad',
                            duration: 350,
                            opacity: [0, 1],
                            translateY: [-15, 0]
                        });
                    }
                }
            }));

            Alpine.data('accordion', () => ({
                container: null,
                init() {
                    this.container = this.$el;
                },
                toggle() {
                    const item = this.$el.parentNode;
                    const other = this.container.querySelectorAll('.accordion-item.is-open');
                    if ( item.classList.contains('is-open') ) {
                        item.classList.remove('is-open');
                    } else {
                        other.forEach((item) => {
                            item.classList.remove('is-open');
                        });
                        item.classList.add('is-open');
                        const content = item.querySelector('.item-content');
                        anime({
                            targets: content,
                            easing: 'easeOutQuad',
                            duration: 350,
                            opacity: [0, 1],
                            translateY: [-15, 0]
                        });
                    }
                }
            }));

            Alpine.data('swiper', () => ({
                container: null,
                init() {
                    const swiper = new Swiper(this.$el, {
                        centeredSlides: true,
                        slidesPerView: 1.95,
                        spaceBetween: 10,
                        breakpoints: {
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            }
                        },
                        loop: true,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }
                    });
                    this.$el.swiper = swiper;
                }
            }));
        });
    }
}

export { App };
